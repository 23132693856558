module.exports = {
  siteTitle: 'CAPSA-MC', // Site title.
  siteTitleAlt: 'Business', // Alternative site title for SEO.
  siteLogo: '/icons/icon-512x512.png', // Logo used for SEO and manifest.
  siteUrl: 'https://dev.capsa-mc.org', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
  siteDescription: 'CAPSA-MC Website', // Website description used for RSS feeds/meta description tag.
  siteRss: '/rss.xml',
  siteFBAppID: '', // FB Application ID for using app insights
  googleTagManagerID: '', // GTM tracking ID.
  disqusShortname: 'CAPSA-MC', // Disqus shortname.
  userName: 'CAPSA-MC',
  userTwitter: '',
  userLocation: 'Maryland, USA',
  userDescription: '',
  copyright: 'Copyright © CAPSA-MC 2021. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#f7e9e6', // Used for setting manifest and progress theme colors.
  backgroundColor: '#ffffff', // Used for setting manifest background color.
  cookieConsent: 'This website uses cookies which are used to collect anonymous information to improve your browsing experience and for analytics and metrics.', // @TODO Add GDPR Cookie Consent
}
