import React, { useState } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'
import SearchBox from '../SearchBox'

const NavBar = () => {
  const [active, setActive] = useState(false)

  const toggleNavBar = () => {
    setActive(!active)
  }

  return (
    <StaticQuery
      query={graphql`
            query SearchIndexQuery {
                siteSearchIndex {
                    index
                }
            }
        `}
      render={data => (
        <nav className='navbar is-fixed-top' aria-label='main navigation'>
          <div className='navbar-brand'>
            <Link to='/' className='navbar-item'>
              <strong>CAPSA-MC</strong>
            </Link>
            <button
              className={`button navbar-burger ${active ? 'is-active' : ''}`}
              data-target='navMenu'
              onClick={toggleNavBar}
            >
              <span />
              <span />
              <span />
            </button>
          </div>
          <div className={`navbar-menu ${active ? 'is-active' : ''}`} id='navMenu'>

            <div className='navbar-end'>
              <Link className='navbar-item' to='/about'>
                About Us
              </Link>
              <Link className='navbar-item' to='/tutoring'>
                Tutoring
              </Link>
              <Link className='navbar-item' to='/calendar'>
                Calendar
              </Link>
              <Link className='navbar-item' to='/resource'>
                Resources
              </Link>
              <Link className='navbar-item' to='/blog'>
                News
              </Link>
              
              <Link className='navbar-item' to='/contact'>
                Contact Us
              </Link>
              <Link className='navbar-item' target='_blank' to='https://www1.capsa-mc.org'>
                Legacy Website
              </Link>                            
            </div>
          </div>
        </nav>
      )}
    />
  )
}

export default NavBar
